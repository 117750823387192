import { createPinia } from 'pinia';
import type { Router } from 'vue-router';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

/*
 * When adding new properties to stores, you should also
 * extend the `PiniaCustomProperties` interface.
 * @see https://pinia.vuejs.org/core-concepts/plugins.html#typing-new-store-properties
 */
declare module 'pinia' {
  export interface PiniaCustomProperties {
    readonly router: Router;
  }
}

/*
 * This function initializes Pinia with plugins and can be imported into your main.ts file.
 */
export function initializePinia() {
  const pinia = createPinia();

  pinia.use(piniaPluginPersistedstate);

  return pinia;
}
