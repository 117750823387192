import { defineRule, configure } from 'vee-validate';
import { all } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import es from '@vee-validate/i18n/dist/locale/es.json';

Object.entries(all).forEach(([name, rule]) => {
  defineRule(name, rule);
});

defineRule(
  'greater_than',
  (value, [target], ctx) => Number(value) > Number(ctx.form[target])
);

defineRule('lower_than', (value, [target], ctx) => {
  if (ctx.form[target]) {
    return Number(value) < Number(ctx.form[target]);
  }

  return true;
});

defineRule('rfc', (value) =>
  new RegExp(/^[A-Z&Ññ]{3,4}\d{6}[A-Z0-9]{3}$/).test(value)
);

defineRule('mex_postal_code', (value) =>
  new RegExp(/^\d{5}([A-Z]\d{2})?$/).test(value)
);

defineRule('usa_zip', (value) => new RegExp(/^\d{5}(-\d{4})?$/).test(value));

defineRule('companyName', (value) => new RegExp(/^(?!.*[?_-]).+$/).test(value));

export default () => {
  configure({
    generateMessage: localize({
      en: {
        code: en.code,
        messages: {
          ...en.messages,
          greater_than: 'The {field} field may greater than {minimumAmount}',
          lower_than: 'The {field} field may lower than {maxAmount}',
          rfc: 'The field {field} is not valid',
          mex_postal_code: 'The field {field} is not valid',
          usa_zip: 'The field {field} is not valid'
        },
      },
      es: {
        code: es.code,
        messages: {
          ...es.messages,
          greater_than: 'El campo {field} debe ser mayor que {minimumAmount}',
          lower_than: 'El campo {field} debe ser menor que {maxAmount}',
          rfc: 'El campo {field} no es válido',
          mex_postal_code: 'El campo {field} no es válido',
          usa_zip: 'El campo {field} no es válido',
        },
      },
    }),
  });
};
