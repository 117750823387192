import axios from 'axios';
import { configuration, configService, token } from './api-config';

const apiService = axios.create({
  baseURL: configService.apiUrl,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});



apiService.interceptors.request.use(
  (config) => configuration(config),
  (error) => {
    Promise.reject(error.response || error.message);
  },
);

apiService.getPayment = (paymentId, queryParams = {}) =>
  apiService.get(`${configService.coreUrl}payment/${paymentId}`, { params: queryParams });

apiService.getDollarRate = () => apiService.get(`${configService.coreUrl}payment/dollar-rate`);

apiService.getInvoicesUpcoming = (invoiceData) =>
  apiService.post(`${configService.coreUrl}payment/invoices-upcoming`, invoiceData);

apiService.fetchCompanyData = (companyId) => apiService.get(`${configService.apiUrl}company/${companyId}`);

apiService.updateCompany = (id, data) =>
  apiService.post(`${configService.apiUrl}company`, {
    id,
    ...data,
  });

apiService.updateUserProfile = (userData) => {
  return apiService.put(`${configService.apiUrl}users/profile`, userData);
};

apiService.manageSubscription = (subscriptionDetails) => {
  const {
    action,
    subscriptionId,
    subscriptionItemId,
    licensesPrice,
    newTierId,
    tierId,
    cardId,
    companyId,
    comment
  } = subscriptionDetails;
  return apiService.post(`${configService.coreUrl}payment/subscriptions`, {
    action,
    subscriptionId,
    subscriptionItemId,
    licensesPrice,
    newTierId,
    tierId,
    cardId,
    companyId,
    comment
  });
};


apiService.payNow = ({ paymentId, stripeSourceId }) => {
  return apiService.post(`${configService.coreUrl}payment/invoices/pay`, {
    paymentId,
    stripeSourceId,
  });
};

export default apiService;
