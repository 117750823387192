import numeral from 'numeral';
import moment from 'moment-timezone';
import { capitalizeFirstLetter } from './index';

export function filesize(size) {
  let calculate = size;

  if (Number.isNaN(calculate)) {
    calculate = 0;
  }

  if (calculate < 1024) {
    return `${calculate}B`;
  }

  calculate /= 1024;

  if (calculate < 1024) {
    return `${calculate.toFixed(2)}KB`;
  }

  calculate /= 1024;

  if (calculate < 1024) {
    return `${calculate.toFixed(2)}MB`;
  }

  calculate /= 1024;

  return `${calculate.toFixed(2)}GB`;
}

export function truncate(str, length = 10) {
  if (!!str && str.length > length && (str.length - length) > 3) {
    const tailSize = Math.floor(length / 2);
    const pivot = str.length - tailSize;
    const strStart = str.substring(0, tailSize);
    const strEnd = str.substring(pivot);

    return `${strStart}...${strEnd}`;
  }
  return str;
}

export default {
  install (app) {
    numeral.register('format', 'time-detail', {
      regexps: {
        format: /[hms]/,
        unformat: /[hms]/
      },
      format: function(value) {
        const hours = Math.floor(value / 60 / 60);
        const minutes = Math.floor((value - (hours * 60 * 60)) / 60);
        const seconds = Math.round(value - (hours * 60 * 60) - (minutes * 60));
        const time = [];

        if (hours > 0) {
          time.push(`${hours}h`);
        }
        if (minutes > 0) {
          time.push(`${minutes.toString().padStart(2, '0')}m`);
        }
        time.push(`${seconds.toString().padStart(2, '0')}s`);

        return time.join(' ');
      },
      unformat: function(string) {
        const timeArray = string.replace(/[hms]/).split(' ');
        let seconds = 0;

        if (timeArray.length === 3) {
          seconds = seconds + (Number(timeArray[0]) * 60 * 60);
          seconds = seconds + (Number(timeArray[1]) * 60);
          seconds = seconds + Number(timeArray[2]);
        } else if (timeArray.length === 2) {
          seconds = seconds + (Number(timeArray[0]) * 60);
          seconds = seconds + Number(timeArray[1]);
        }
        return Number(seconds);
      }
    });

    app.config.globalProperties.numFormat = (value, format = '0,0') => numeral(value).format(format);
    app.config.globalProperties.capitalizeString = (value) => capitalizeFirstLetter(value?.toLowerCase());
    app.config.globalProperties.filesize = filesize;
    app.config.globalProperties.truncate = truncate;
    app.config.globalProperties.$moment = moment;
    app.config.globalProperties.$copyText = (text) => navigator.clipboard.writeText(text);
    app.provide('moment', moment);
  }
};
